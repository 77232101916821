import React from "react"
import {  Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Map from '../components/map';


const styles = theme => ({
  gridContainer: {
    width: "100%",
    marginTop: "56px",
    minHeight: "500px",
    height: "100%",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",

  },
  gridContainerSecond: {
    width: "100%",
    height: "auto",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    paddingTop: "50px",
    paddingRight:"10px",
    paddingBottom:"50px",
    background: "#4A6476"
  },
  headline: {
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
    marginBottom: "20px"
  },
  phone: {
    textDecoration: "none",
    color: "inherit"
  }
});



class Contact extends React.Component {


  render(){
    const {classes } = this.props;

    return (
    <Layout>
      <Grid className = {classes.gridContainer} container  >
        <Grid className = {classes.gridContainerSecond} container>
            <Grid item xs={12} >
                <Typography className={classes.headline} variant="h3" >
                Map for Directions
                </Typography>
            </Grid>
            <Map/>
        </Grid>

        <Footer/>
      </Grid>
    </Layout>
  );
  }

}


export default withStyles(styles)(Contact);
